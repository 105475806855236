import React, { useState, useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import styled from "styled-components";
// assets
// styles
const menu_style = {
  // padding: "2%",
  fontSize: "calc(20px + 0.5vw)",
  color: "#595652",
};
const mobile_menu_style = {
  padding: "2%",
  fontSize: "calc(16px + 0.5vw)",
  color: "#595652",
};
const link_style = {
  color: "#595652",
};
const AnimatedLinks = styled.div`
  -webkit-transition: transform 0.2s;
  -ms-transition: transform 0.2s;
  transition: transform 0.2s;
  :hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;
// component
const Menu = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 960);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 960);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <Navbar
      bg="transparent"
      variant="dark"
      expand="lg"
      style={isDesktop ? menu_style : mobile_menu_style}
    >
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        style={{ color: "white" }}
      />
      <Navbar.Collapse id="basic-navbar-nav">
        {/* <Navbar.Brand href="#home">
          <img
            src={title_logo}
            width="100%"
            // height="30"
            // className="d-inline-block align-top"
            alt="SwampHacks VIII logo"
          />
        </Navbar.Brand> */}
        <Nav>
          <AnimatedLinks>
            <Nav.Item>
              <Nav.Link
                href="#About"
                eventKey="About"
                className="link"
                style={link_style}
              >
                About
              </Nav.Link>
            </Nav.Item>
          </AnimatedLinks>
          <AnimatedLinks>
            {" "}
            <Nav.Item>
              <Nav.Link
                href="#Schedule"
                eventKey="Schedule"
                className="link"
                style={link_style}
              >
                Schedule
              </Nav.Link>
            </Nav.Item>
          </AnimatedLinks>
          <AnimatedLinks>
            {" "}
            <Nav.Item>
              <Nav.Link
                href="#FAQ"
                eventKey="FAQ"
                className="link"
                style={link_style}
              >
                FAQ
              </Nav.Link>
            </Nav.Item>
          </AnimatedLinks>
          <AnimatedLinks>
            {" "}
            <Nav.Item>
              <Nav.Link
                href="#Sponsors"
                eventKey="Sponsors"
                className="link"
                style={link_style}
              >
                Sponsors
              </Nav.Link>
            </Nav.Item>
          </AnimatedLinks>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Menu;
