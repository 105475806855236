import React, { useState, useEffect } from "react";
// react-bootstrap
import { Row, Col } from "react-bootstrap";
// assets
import air_balloons from "../assets/gifs/air_balloons.gif";
// styles
import { body_text_style, heading2_style } from "../styles";
const house_col_style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
// #########################################
// Mini-Component
// #########################################
let desktop_version = (
  <Row>
    <Col>
      <h2 style={heading2_style}>About SwampHacks</h2>

      <p
        style={{
          color: "white",
          fontSize: "calc(16px + 0.25vw)",
          marginBottom: "4%",
          textShadow: "1px 1px 1px black",
        }}
      >
        SwampHacks is 36 hours of learning, coding, and creativity. For a whole
        weekend, students nationwide come together as developers, engineers, and
        designers. We encourage people of all ages, genders, and backgrounds to
        attend, learn, and innovate!
      </p>
      <p style={{ ...body_text_style, textShadow: "1px 1px 1px black" }}>
        This year, SwampHacks will be welcoming hundreds of participants from
        all over the country to experience software development from the comfort
        of your own home! Best of all, no experience is necessary to
        participate! From seasoned mentors to intriguing workshops, SwampHacks
        supports all student activities and encourages “outside the box”
        thinking. Join us to meet some of the brightest minds in the world and
        have an awesome weekend in our virtual swamp.
      </p>
    </Col>

    <Col style={house_col_style}>
      <Row>
        <img
          src={air_balloons}
          alt="air balloons"
          style={{
            objectFit: "cover",
            objectPosition: "center",
            height: "50vh",
            width: "70vw",
          }}
        />
      </Row>
    </Col>
  </Row>
);
let mobile_version = (
  <Row>
    <h2 style={heading2_style}>About SwampHacks</h2>
    <p
      style={{
        color: "white",
        fontSize: "calc(16px + 0.25vw)",
        marginBottom: "4%",
        textShadow: "1px 1px 1px black",
      }}
    >
      SwampHacks is 36 hours of learning, coding, and creativity. For a whole
      weekend, students nationwide come together as developers, engineers, and
      designers. We encourage people of all ages, genders, and backgrounds to
      attend, learn, and innovate!
    </p>
    <p style={{ ...body_text_style, textShadow: "1px 1px 1px black" }}>
      This year, SwampHacks will be welcoming over 900 participants from all
      over to experience software development from the comfort of your own home!
      Best of all, no experience is necessary to participate! From seasoned
      mentors to intriguing workshops, SwampHacks supports all student
      activities and encourages “outside the box” thinking. Join us to meet some
      of the brightest minds in the world and have an awesome weekend in our
      virtual swamp.
    </p>
  </Row>
);
// #########################################
// Component
// #########################################
const AboutSection = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 960);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 960);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return <div>{isDesktop ? desktop_version : mobile_version}</div>;
};

export default AboutSection;
