import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import styled from "styled-components";
import {
  /* Meta,
   Bloomberg_Dark,
   L3Harris, */
  InfoTech,
  /* Accenture,
    Sticker_Mule,
    AssemblyAI, */
  Google,
} from "../assets/sponsors";
// styles
import { body_text_style, heading2_style } from "../styles";
const sponsor_style = {
  padding: "16px",
  border: "1px solid #5abc36",
  borderRadius: "8px",
  display: "grid",
  placeItems: "center",
  background: "rgba(255, 255, 255, 0.45)",
};
const coming_soon_text_style = {
  color: "white",
  fontSize: "calc(16px + 1vw)",
  opacity: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",
  textShadow: "1px 1px 30px black",
};

const coming_soon_card_style = {
  background: "rgba(255, 255, 255, 0.33)",
  borderRadius: "10px",
  // borderColor: "#22B573",
  alignText: "center",
  padding: "10%",
  margin: "0px",
  // paddingTop: "40px",
  // paddingBottom: "40px",
};
const AnimatedSponsor = styled.img`
  -webkit-transition: transform 0.2s;
  -ms-transition: transform 0.2s;
  transition: transform 0.2s;
  :hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;
// #########################################
// Mini-Components
// #########################################
let coming_soon_card = (
  <div>
    {/* <img src={knight} alt="knight" style={knight_style} /> */}
    <h2 style={heading2_style}>Our Amazing Sponsors</h2>

    <Card style={coming_soon_card_style}>
      <h3 style={coming_soon_text_style}>Coming Soon!</h3>
    </Card>

    <div
      style={{
        display: "grid",
        placeItems: "center",
        color: "white",
        textShadow: "1px 1px 1px black",
      }}
    >
      <p>Interested in sponsoring? Reach out at sponsors@swamphacks.com!</p>
    </div>
  </div>
);
let sponsors_grid = (
  <div style={body_text_style}>
    <h2 style={heading2_style}>Sponsored by</h2>

    {/* Silver */}
    <Row>
      <Col style={sponsor_style} className="mx-1 my-1">
        <AnimatedSponsor
          src={InfoTech}
          alt="InfoTech"
          style={{
            width: "35%",
            minWidth: "100px",
            height: "auto",
            margin: "1%",
            borderRadius: "10px",
          }}
        />
      </Col>
      <Col style={sponsor_style} className="mx-1 my-1">
        <AnimatedSponsor
          src={Google}
          alt="Google"
          style={{
            width: "45%",
            minWidth: "100px",
            height: "auto",
            margin: "1%",
            borderRadius: "10px",
          }}
        />
      </Col>
      {/* <Col style={sponsor_style} className="mx-1 my-1">
        <AnimatedSponsor
          src={Accenture}
          alt="Accenture"
          style={{
            width: "35%",
            minWidth: "100px",
            height: "auto",
            margin: "1%",
            borderRadius: "10px",
          }}
        />
      </Col> */}
    </Row>

    {/* Bronze */}
    {/* <Row>
      <Col style={sponsor_style} className="mx-1 my-1">
        <AnimatedSponsor
          src={L3Harris}
          alt="L3Harris"
          style={{
            width: "35%",
            minWidth: "100px",
            height: "auto",
            margin: "1%",
            borderRadius: "10px",
          }}
        />
      </Col>
      <Col style={sponsor_style} className="mx-1 my-1">
        <AnimatedSponsor
          src={Meta}
          alt="Meta"
          style={{
            width: "35%",
            minWidth: "100px",
            height: "auto",
            margin: "1%",
            borderRadius: "10px",
          }}
        />
      </Col>
      <Col style={sponsor_style} className="mx-1 my-1">
        <AnimatedSponsor
          src={Bloomberg_Dark}
          alt="Bloomberg"
          style={{
            width: "35%",
            minWidth: "100px",
            height: "auto",
            margin: "1%",
            borderRadius: "10px",
          }}
        />
      </Col>
    </Row> */}

    {/* None */}
    {/* <Row>
      <Col style={sponsor_style} className="mx-1 my-1">
        <AnimatedSponsor
          src={Google}
          alt="Google"
          style={{
            width: "30%",
            minWidth: "100px",
            height: "auto",
            margin: "1%",
            borderRadius: "10px",
          }}
        />
      </Col>
      <Col style={sponsor_style} className="mx-1 my-1">
        <AnimatedSponsor
          src={AssemblyAI}
          alt="Sticker Mule"
          style={{
            width: "30%",
            minWidth: "100px",
            height: "auto",
            margin: "1%",
            borderRadius: "10px",
          }}
        />
      </Col>
      <Col style={sponsor_style} className="mx-1 my-1">
        <AnimatedSponsor
          src={Sticker_Mule}
          alt="Sticker Mule"
          style={{
            width: "30%",
            minWidth: "100px",
            height: "auto",
            margin: "1%",
            borderRadius: "10px",
          }}
        />
      </Col>
    </Row> */}
  </div>
);

// #########################################
// Component
// #########################################
const SponsorSection = () => {
  let sponsors_coming_soon = false;
  return <div>{sponsors_coming_soon ? coming_soon_card : sponsors_grid}</div>;
};

export default SponsorSection;
