import React from "react";
// imports
import { Container, Row, Col } from "react-bootstrap";
import TeamCard from "../components/TeamCard";
// ########################
//  assets
// ########################
import Alexa from "../assets/team-images/Alexa.png";
import Allan from "../assets/team-images/Allan.jpeg";
import Angelina from "../assets/team-images/Angelina.jpeg";
import Carl from "../assets/team-images/Carl.jpeg";
import Gabe from "../assets/team-images/Gabe.jpeg";
import Grant from "../assets/team-images/Grant.png";
import Lily from "../assets/team-images/Lily.png";
import Nash from "../assets/team-images/Nash.jpeg";
import Rachel from "../assets/team-images/Rachel.jpeg";
import Rachel_West from "../assets/team-images/Rachel_West.jpeg";
import Rithika from "../assets/team-images/Rithika.jpg";
import Zach from "../assets/team-images/Zach.jpeg";
// ########################
//  Styles
// ########################
const centerDiv = {
  display: "grid",
  placeItems: "center",
  // backgroundColor: "grey",
};
// ########################
//  Component
// ########################
const Team = () => {
  return (
    // <div style={wholeContainerStyle}>
    <Container style={centerDiv} className="my-5">
      <Row>
        <h1>Meet the Team!</h1>
      </Row>
      <Row style={{ textAlign: "center" }}>
        <h4>
          SwampHacks is organized and run completely by students, for students.
          These are the ones that made it happen in 2020.
        </h4>
      </Row>

      {/* ---- Executive ---- */}
      <Row className="my-2">
        <Col style={centerDiv}>
          <TeamCard
            name="Joel Rios"
            position="Executive Co-Director"
            image="https://avatars3.githubusercontent.com/u/21366420?s=400&u=52efa44306212ccbcf916229631ed28c0d9d4f9f&v=4"
          />
        </Col>
        <Col style={centerDiv}>
          <TeamCard
            name="Grant Wise"
            position="Executive Co-Director"
            image={Grant}
          />
        </Col>
        <Col style={centerDiv}>
          <TeamCard name="Rachel Orr" position="Treasurer" image={Rachel} />
        </Col>
      </Row>

      {/* ---- Tech ---- */}
      <Row className="my-2">
        <Col style={centerDiv}>
          <TeamCard
            name="Angelina Kapiniaris"
            position="Co-Director of Technology"
            image={Angelina}
          />
        </Col>
        <Col style={centerDiv}>
          <TeamCard
            name="Allan DoLago"
            position="Co-Director of Technology"
            image={Allan}
          />
        </Col>
        <Col style={centerDiv}>
          <TeamCard
            name="Nash T. Dean"
            position="Co-Director of Technology"
            image={Nash}
          />
        </Col>
      </Row>

      {/* ---- Art ---- */}
      <Row className="my-2">
        <Col style={centerDiv}>
          <TeamCard name="Carl Noll" position="Director of Art" image={Carl} />
        </Col>
        <Col style={centerDiv}>
          <TeamCard
            name="Lily Maloney"
            position="Art Assistant Director"
            image={Lily}
          />
        </Col>
        <Col style={centerDiv}>
          <TeamCard
            name="Rachel West"
            position="Art Assistant Director"
            image={Rachel_West}
          />
        </Col>
      </Row>

      {/* ---- Row ---- */}
      <Row className="my-2">
        <Col style={centerDiv}>
          <TeamCard
            name="Gabe Rodriguez Torres"
            position="Co-Director of Sponsorship"
            image={Gabe}
          />
        </Col>
        <Col style={centerDiv}>
          <TeamCard
            name="Rithika Bikkavilli"
            position="Co-Director of Sponsorship"
            image={Rithika}
          />
        </Col>
        <Col style={centerDiv}>
          <TeamCard
            name="Alexa Ajello"
            position="Director of Marketing"
            image={Alexa}
          />
        </Col>
      </Row>

      {/* ---- Row ---- */}
      <Row className="my-2">
        <Col style={centerDiv}>
          <TeamCard
            name="Zachary Speaks"
            position="Director of Logistics"
            image={Zach}
          />
        </Col>
        {/* <Col></Col>
          <Col></Col>
          <Col></Col> */}
      </Row>
    </Container>
    // </div>
  );
};

export default Team;
