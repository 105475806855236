import { CSSProperties } from "react";
import { Row } from "react-bootstrap";
import styled from "styled-components";
// components
import {
  // Menu,
  AboutSection,
  ScheduleSection,
  FAQSection,
  SponsorSection,
  // DungeonSection,
  // FooterSection,
} from "../components";
import FooterSection from "../components/FooterSection";
// assets
import background_banner from "../assets/background_banner.jpg";
import bubbles from "../assets/gifs/bubbles.gif";
import astronaut from "../assets/gifs/astronaut.gif";
import asteroids from "../assets/gifs/asteroid_belt.gif";
import Banner from "../components/Banner";
import { device } from "../styles";
// styles
const about_style = {
  marginLeft: "3%",
  padding: "10vh 0",
};
// animations
const SideFloatingImg = styled.img`
  width: 20vw;
  animation: x_floating 3s infinite;
  @keyframes x_floating {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(25px, 0);
    }
    100% {
      transform: translate(-0px, 0);
    }
  }
`;

const BackgroundDiv = styled.div`
  font-family: Exo;
  min-height: 100vh;
  color: #7ec0ae;
  overflow: hidden;
  position: relative;
  z-index: 0;

  div.background {
    position: relative;
    z-index: -1;

    * {
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      z-index: 1;
      position: absolute;
    }
  }

  div > .background_banner {
    z-index: 0;
  }

  div > .bubbles {
    margin-top: 90%;
  }

  div > .astronaut {
    left: 80%;
    width: 50vw;
    margin-top: 400%;
  }

  div > .asteroids {
    width: 108vw;
    margin-top: 420%;
  }

  @media ${device.tablet} {
    div > .background_banner {
      width: 150vw;
    }

    div > .bubbles {
      margin-top: 150vw;
    }

    div > .astronaut {
      margin-top: 850%;
    }

    div > .asteroids {
      margin-top: 900%;
    }
  }

  @media ${device.mobileL} {
    div > .background_banner {
      width: 200vw;
    }

    div > .bubbles {
      margin-top: 180vw;
      width: 200vw;
    }
  }
`;
// ##########################
// Component
// ##########################
const Home = () => {
  return (
    <BackgroundDiv>
      <div className="background">
        <img src={asteroids} alt="asteroid belt" className="asteroids" />
        <img src={astronaut} alt="astronaut" className="astronaut" />
        <img src={bubbles} alt="bubbles" className="bubbles" />
        <img
          src={background_banner}
          alt="background banner"
          className="background_banner"
        />
      </div>

      <Banner />

      <Row style={about_style} id="About">
        <AboutSection />
      </Row>
      <Row className="m-5" id="Schedule">
        <ScheduleSection />
      </Row>
      <Row className="m-5" id="FAQ">
        <FAQSection />
      </Row>

      {/* <div> 
        <img
          src={knight_loop}
          style={isDesktop ? knight_style : mobile_knight_style}
          alt="knight"
        />
      </div>*/}

      <Row className="m-5" id="Sponsors">
        <SponsorSection />
      </Row>

      {/* <Row>
        <img src={dungeon2} alt="dungeon" style={{ width: "100%" }} />
      </Row> */}

      <FooterSection />
    </BackgroundDiv>
  );
};

export default Home;
