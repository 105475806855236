const heading1_style = {
  // color: "#22B573",
  color: "white",
  fontSize: "calc(48px + 1vw)",
};
const heading2_style = {
  // color: "#22B573",
  color: "white",
  fontSize: "calc(36px + 1vw)",
};
const heading3_style = {
  color: "#7EC0AE",
  fontSize: "calc(14px + 0.75vw)",
};
const body_text_style = {
  // fontFamily: "Roboto",
  color: "white",
  fontSize: "calc(16px + 0.25vw)",
  alignText: "justify",
};

const center_div = {
  display: "grid",
  placeItems: "center",
};

const deviceSizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

const device = Object.fromEntries(
  Object.entries(deviceSizes).map(([k, v]) => [k, `(max-width: ${v})`])
);

const deviceSizesArray = Object.values(deviceSizes).map((e) =>
  parseInt(e.substring(0, e.length - 2))
);

export {
  body_text_style,
  heading1_style,
  heading2_style,
  heading3_style,
  center_div,
  deviceSizes,
  device,
};
