import React from "react";
// styled-components
import styled from "styled-components";
// assets
import facebookIcon from "../assets/socials/fb.svg";
import instagramIcon from "../assets/socials/insta.svg";
import twitterIcon from "../assets/socials/twitter.svg";
import linkedInIcon from "../assets/socials/linkedin.svg";
import emailIcon from "../assets/socials/gmail.svg";
// styles
const AnimatedIcon = styled.img`
  -webkit-transition: transform 0.2s;
  -ms-transition: transform 0.2s;
  transition: transform 0.2s;
  :hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

const iconStyle = {
  width: "8%",
  minWidth: "26px",
  height: "auto",
  margin: "1%",
  cursor: "pointer",
};

const IconBar = () => {
  return (
    <div>
      {/* <img
        src={facebookIcon}
        alt="facebookIcon"
        style={{
          width: "8%",
          minWidth: "26px",
          height: "auto",
          margin: "1%",
          cursor: "pointer",
        }}
        onClick={() => {
          window.open("https://www.facebook.com/SwampHacks/", "_blank");
        }}
      /> */}

      <AnimatedIcon
        src={facebookIcon}
        alt="facebookIcon"
        style={iconStyle}
        onClick={() => {
          window.open("https://www.facebook.com/SwampHacks/", "_blank");
        }}
      />

      <AnimatedIcon
        src={instagramIcon}
        alt="instagramIcon"
        style={iconStyle}
        onClick={() => {
          window.open("https://www.instagram.com/ufswamphacks/", "_blank");
        }}
      />

      <AnimatedIcon
        src={twitterIcon}
        alt="twitterIcon"
        style={iconStyle}
        onClick={() => {
          window.open("https://twitter.com/swamphacks", "_blank");
        }}
      />

      <AnimatedIcon
        src={linkedInIcon}
        alt="linkedInIcon"
        style={iconStyle}
        onClick={() => {
          window.open("https://www.linkedin.com/company/swamphacks/", "_blank");
        }}
      />

      <a href="mailto:info@swamphacks.com" target="_top">
        <AnimatedIcon src={emailIcon} alt="instagramIcon" style={iconStyle} />
      </a>
    </div>
  );
};

export default IconBar;
