import React from "react";
// dependencies
import { Link } from "react-router-dom";
import { Result, Button } from "antd";
import { Card } from "react-bootstrap";
// styles
const notFoundPageStyle = {
  fontFamily: "Pixellari",
  backgroundColor: "#0B0B0B",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const cardStyle = {
  width: "75%",
  padding: "5%",
  borderRadius: "10px",
};
const centeredDivStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
// component
const NotFound = () => {
  return (
    <div style={notFoundPageStyle}>
      <Card style={cardStyle}>
        <div style={centeredDivStyle}>
          <Result
            status="404"
            title="404"
            subTitle="This page does not exist."
            extra={
              <Link to="/">
                <Button type="primary">Back Home</Button>
              </Link>
            }
            style={{ width: "50vw" }}
          />
        </div>
      </Card>
    </div>
  );
};

export default NotFound;
