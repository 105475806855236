import React from "react";
// imports
import { Card } from "react-bootstrap";
// ########################
//  Styles
// ########################
const cardStyle = {
  borderRadius: "10px",
  width: "300px",
};
const centerDiv = {
  display: "grid",
  placeItems: "center",
};
const imgStyle = {
  maxWidth: "150px",
  borderRadius: "10px",
};

// ########################
//  Types
// ########################
type CardProps = {
  name: string;
  position: string;
  image?: string;
};

// ########################
//  Component
// ########################
const TeamCard = ({ name, position, image }: CardProps) => {
  return (
    <div style={cardStyle}>
      <Card.Body style={centerDiv}>
        <img src={image} alt="Profile Img" style={imgStyle} className="my-2" />
        <Card.Title>{name}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{position}</Card.Subtitle>
      </Card.Body>
    </div>
  );
};

export default TeamCard;
