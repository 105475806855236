import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
// components
import { IconBar } from "../components";
import heart from "../assets/svgs/heart.svg";
// styles
import { center_div } from "../styles";
const outer_layer_style = {
  fontSize: "calc(8px + 0.50vw)",
  background: "rgba(255,255,255,0.0)",
  padding: "20px",
};
const middle_layer_style = {
  background: "rgba(255,255,255,0.15)",
  padding: "20px",
  borderRadius: "10px",
};
const inner_layer_style = {
  background: "rgba(19,34,33,0.33)",
  padding: "20px",
  borderRadius: "10px",
};
const text_style = {
  color: "rgb(255, 255, 255)",
  fontSize: "calc(8px + 0.50vw)",
};
const AnimatedLinks = styled.div`
  -webkit-transition: transform 0.2s;
  -ms-transition: transform 0.2s;
  transition: transform 0.2s;
  :hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;
// #########################################
// Mini-Components
// #########################################
let desktop_version = (
  <Row>
    {/* Iconbar */}
    <Col
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <IconBar />
    </Col>

    {/* Made with love in Gainesville */}
    <Col
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginTop: "1%",
      }}
    >
      <p style={text_style}>
        Made with <img src={heart} alt="heart" style={{ width: "30%" }} /> in
        Gainesville, FL.
      </p>
    </Col>

    {/* Other links */}
    <Col
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div style={{ paddingTop: "2%" }}>
        {/* Code of Conduct */}
        <AnimatedLinks>
          <a
            href="https://docs.google.com/document/d/1YN3zePq6iKIqW0IUrc5BV-YjiwVCziOdScFWnY_jvUY/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            <p style={text_style}>Code of Conduct</p>
          </a>
        </AnimatedLinks>

        <AnimatedLinks>
          <a
            href="https://2022.swamphacks.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            <p style={text_style}>Last Year's Site</p>
          </a>
        </AnimatedLinks>
        {/* Last Year's Site */}

        {/* Last Year's Devpost */}
        <AnimatedLinks>
          <a
            href="https://swamphacks-viii.devpost.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            <p style={text_style}>Last Year's Devpost</p>
          </a>
        </AnimatedLinks>
      </div>
    </Col>
  </Row>
);
let mobile_version = (
  <Row>
    <Col>
      {/* Iconbar */}
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <IconBar />
      </Row>

      {/* Made with love in Gainesville */}
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginTop: "4%",
        }}
      >
        <p style={text_style}>
          Made with <img src={heart} alt="heart" style={{ width: "30%" }} /> in
          Gainesville, FL.
        </p>
      </Row>

      {/* Other links */}
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginTop: "4%",
        }}
      >
        <div style={{ paddingTop: "2%" }}>
          {/* Code of Conduct */}
          <a
            href="https://docs.google.com/document/d/1YN3zePq6iKIqW0IUrc5BV-YjiwVCziOdScFWnY_jvUY/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            <p style={text_style}>Code of Conduct</p>
          </a>
          {/* Last Year's Site */}
          <a
            href="https://2022.swamphacks.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            <p style={text_style}>Last Year's Site</p>
          </a>
          {/* Last Year's Devpost */}
          <a
            href="https://swamphacks-viii.devpost.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            <p style={text_style}>Last Year's Devpost</p>
          </a>
        </div>
      </Row>
    </Col>
  </Row>
);
// #########################################
// Component
// #########################################
const FooterSection = () => {
  // Check if it's mobile or desktop
  const [isDesktop, setDesktop] = useState(window.innerWidth > 960);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 960);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div style={center_div}>
      <div style={outer_layer_style}>
        <div style={middle_layer_style}>
          <div style={inner_layer_style}>
            <div>{isDesktop ? desktop_version : mobile_version}</div>
          </div>
        </div>
      </div>
      <p
        className="py-2"
        style={{ color: "white", textShadow: "1px 1px 1px black" }}
      >
        Copyright © 2023 SwampHacks
      </p>
    </div>
  );
};

export default FooterSection;
