import { Row, Tab, Table, Nav } from "react-bootstrap";
// styles
import { body_text_style, heading2_style } from "../styles";
const schedule_section_style = {
  fontSize: "calc(10px + 0.50vw)",
  background: "rgb(148, 194, 233, 0.2)",
  borderRadius: 20,
  padding: "10px",
  // textShadow: "1px 1px 20px black",
};
const nav_item_style = {
  marginRight: "2%",
};
const nav_link_style = {
  fontSize: "calc(12px + 0.75vw)",
  background: "rgb(0, 0, 128, 0.1)",
  borderRadius: "10px",
  color: "white",
};

// variables

// #########################################
// Mini-Components
// #########################################
let friday_schedule = (
  <Table>
    <tbody style={body_text_style}>
      <tr>
        <td>6:00 PM - 7:00 PM EST</td>
        <td>Check-In</td>
      </tr>
      <tr>
        <td>7:00 PM - 8:00 PM EST</td>
        <td>Opening Ceremony</td>
      </tr>
      <tr>
        <td>8:00 PM - 10:00 PM EST</td>
        <td>Team Building & Brainstorming</td>
      </tr>
      <tr>
        <td>10:00 PM EST</td>
        <td>Hacking Begins!</td>
      </tr>
    </tbody>
  </Table>
);
let saturday_schedule = (
  <p style={body_text_style} className="my-4">
    Workshops and Activities all day!
  </p>
);
let sunday_schedule = (
  <Table>
    <tbody style={body_text_style}>
      <tr>
        <td>10:00 AM EST</td>
        <td>Hacking Ends!</td>
      </tr>
      <tr>
        <td>10:00 AM - 11:00 AM EST</td>
        <td>Project Submissions Devpost + Video Demos</td>
      </tr>
      <tr>
        <td>11:00 AM - 2:00 PM EST</td>
        <td>Judging</td>
      </tr>
      <tr>
        <td>2:00 PM - 3:00 PM EST</td>
        <td>Closing Ceremony</td>
      </tr>
    </tbody>
  </Table>
);

let schedule_component = (
  <div style={schedule_section_style}>
    <h2 style={heading2_style}>Schedule</h2>
    <Tab.Container defaultActiveKey="friday">
      <div>
        <Nav>
          <Nav.Item style={nav_item_style}>
            <Nav.Link style={nav_link_style} eventKey="friday">
              Friday
            </Nav.Link>
          </Nav.Item>
          <Nav.Item style={nav_item_style}>
            <Nav.Link style={nav_link_style} eventKey="saturday">
              Saturday
            </Nav.Link>
          </Nav.Item>
          <Nav.Item style={nav_item_style}>
            <Nav.Link style={nav_link_style} eventKey="sunday">
              Sunday
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>

      <Row>
        <Tab.Content style={{ marginTop: "4%" }}>
          <Tab.Pane eventKey="friday">{friday_schedule}</Tab.Pane>
          <Tab.Pane eventKey="saturday">{saturday_schedule}</Tab.Pane>
          <Tab.Pane eventKey="sunday">{sunday_schedule}</Tab.Pane>
        </Tab.Content>
      </Row>
    </Tab.Container>
  </div>
);

// #########################################
// Component
// #########################################
const ScheduleSection = () => {
  return (
    <div style={{ margin: "10vh 0" }}>
      <Row>{schedule_component}</Row>
    </div>
  );
};

export default ScheduleSection;
