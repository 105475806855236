import React, { FC } from "react";
import { Accordion, Card, Row } from "react-bootstrap";
import { BsFillCaretRightFill } from "react-icons/bs";
// styles
import { heading2_style } from "../styles";
const FAQContainerStyle = {
  color: "white",
  fontSize: "calc(16px + 0.25vw)",
};
const iconStyle = { 
  marginRight: "1%", 
  color: "rgb(34, 181, 115)" 
};
const card_style = {
  background: "rgba(19,34,33,0.4)",
  margin: "20px 0px",
  borderColor: "rgb(34, 181, 115)",
  fontSize: "calc(20px + 0.25vw)",
};
const card_body_style = {
  background: "rgba(10,18,17,0.4)",
  fontSize: "calc(16px + 0.25vw)",
  borderRadius: "0px 0px 10px 10px",
};

const section_style = {
  background: "rgba(148, 194, 233, 0.2)",
  padding: "20px",
  margin: "20px 0px",
  borderRadius: "10px",
};

// #################
// mini-components
// #################
let FAQs = (
  <div style={section_style}>
    <h2 style={heading2_style}>Frequently Asked Questions</h2>
    <Row>
      <Accordion style={FAQContainerStyle}>
        <Card style={card_style}>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            <BsFillCaretRightFill style={iconStyle} />
            What is SwampHacks?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body style={card_body_style}>
              SwampHacks is a weekend long event of learning, coding, and
              creativity where students nationwide come together as developers,
              engineers, and designers to learn new technologies and build
              innovative projects. We encourage people of all ages, genders, and
              backgrounds to attend and learn new skills!
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card style={card_style}>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            <BsFillCaretRightFill style={iconStyle} />
            When and where is SwampHacks?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body style={card_body_style}>
              SwampHacks will be virtually held between the dates of January
              27-29th, 2023. You will be logging into the hackathon through our
              online platform and will be able to attend workshops, activities,
              and meet with your team. Details to be provided soon!
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card style={card_style}>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            <BsFillCaretRightFill style={iconStyle} />
            Help, this is my first hackathon!
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body style={card_body_style}>
              <p>
                Amazing! SwampHacks welcomes everyone regardless of skill level.
                As long as you have a passion for development and a willingness
                to learn we can guide you with our numerous mentors and beginner
                workshops.
              </p>
              {/* <p>Since you will be attending through the
              comfort of your own home, all you need is a device that connects
              to the internet! If you really want to stimulate a hackathon
              environment, we encourage that you spend the weekend wearing the
              same clothes, eat only junk food, and spend your night sleeping on
              the floor.</p> */}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card style={card_style}>
          <Accordion.Toggle as={Card.Header} eventKey="4">
            <BsFillCaretRightFill style={iconStyle} />
            What's the cost?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body style={card_body_style}>
              SwampHacks is completely free to attend!
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card style={card_style}>
          <Accordion.Toggle as={Card.Header} eventKey="5">
            <BsFillCaretRightFill style={iconStyle} />
            Am I eligible to attend?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Card.Body style={card_body_style}>
              <p>
                If you are currently attending a college or university, then
                yes!
              </p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card style={card_style}>
          <Accordion.Toggle as={Card.Header} eventKey="9">
            <BsFillCaretRightFill style={iconStyle} />
            Do I need a team?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="9">
            <Card.Body style={card_body_style}>
              <p>
                It's not required to be in a team, but it's highly encouraged.
                Work with others and forge new connections, but, above all,
                learn and have fun!
                {/* You can either form your own team beforehand, attend our team
                formation workshop, or participate without a team. It's
                completely up to you! We encourage you to work with others and
                forge new connections, but, above all, learn and have fun! */}
                !
              </p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Row>
  </div>
);

// #################
// component
// #################
const FAQSection: FC = () => {
  return <div>{FAQs}</div>;
};

export default FAQSection;
