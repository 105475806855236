import { Card } from "react-bootstrap";
import { heading2_style } from "../styles";
// styles
const page_style = {
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Exo",
  background: "#1E3533",
};
const card_style = {
  width: "75%",
  borderRadius: "10px",
  background: "#121f1e",
};
// ################
// Mini-Components
// ################
let closed_application = (
  <div style={page_style}>
    <Card style={card_style}>
      <div style={{ textAlign: "center" }}>
        <h3 style={heading2_style} className="my-4">
          Applications are currently closed.
        </h3>
      </div>
    </Card>
  </div>
);

let open_application = (
  <div
    style={{
      backgroundColor: "white",
    }}
  >
    <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
    <iframe
      title="Airtable form"
      className="airtable-embed airtable-dynamic-height"
      src="https://airtable.com/embed/shr5FqHnGxXp8erXb?backgroundColor=blue"
      frameBorder="0"
      // onmousewheel=""
      width="100%"
      height="calc(100vh - 5px)"
      style={{
        background: "transparent",
        border: "1px solid #ccc",
        minHeight: "calc(100vh - 5px)",
      }}
    ></iframe>
  </div>
);
// ################
// Component
// ################
const Application = () => {
  let applicationsOpen = false;
  return applicationsOpen ? open_application : closed_application;
};

export default Application;
