import { Link } from "react-router-dom";
import styled from "styled-components";
import { heading2_style, device } from "../styles";
import { default as astro_logo } from "../assets/svgs/astro_gator.svg";

const BannerDiv = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  * {
    text-transform: uppercase;
    color: white;
  }

  h1 {
    font-size: calc(85px + 1vh);
    margin: 10px 0 5px 0;
  }

  a {
    background-color: #5abc36;
    font-size: ${heading2_style.fontSize};
    padding: 12px 25px;
    border-radius: 7px;
    box-shadow: 0px 10px 5px -2px rgb(105, 205, 68) inset;
    transition: all 0.1s ease;
  }

  > a:hover {
    color: white;
    background-color: rgb(105, 205, 68);
  }

  img {
    width: 40vh;
  }

  @media ${device.tablet} {
    height: 80vh;

    h1 {
      font-size: calc(48px);
      margin: 0 0 20px 0% !important;
    }

    h2,
    a {
      font-size: calc(14px + 1vh);
    }

    img {
      width: 30vh;
    }
  }

  @media ${device.mobileL} {
    height: 70vh;

    h1 {
      font-size: calc(36px + 1vh);
    }
  }

  @media ${device.mobileM} {
    height: 60vh;

    h1 {
      font-size: calc(24px + 1vh);
    }
    img {
      width: 20vh;
    }
  }

  @media ${device.mobileS} {
    height: auto;
    padding: 100px 0 0 0;
  }
`;

const Banner = () => {
  return (
    <BannerDiv>
      <h1>Swamphacks IX</h1>
      <h2 style={{ marginBottom: 0 }}>University of Florida</h2>
      <h2>Jan 27-29, 2023</h2>
      <Link to="/apply">Apply here!</Link>
      <img src={astro_logo} alt="Astronaut gator logo" />
    </BannerDiv>
  );
};

export default Banner;
