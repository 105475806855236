import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";

import { Home, Team, ThankYou, NotFound, Application } from "./pages";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/apply" exact component={Application} />
          <Route path="/team" exact component={Team} />
          <Route path="/thank-you" exact component={ThankYou} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
